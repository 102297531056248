import React from "react";
import { inject, observer } from "mobx-react";
import {
  convertCurrency,
  convertCurrencySummaryLocation,
  datePicker,
} from "../helpers";
import { Translation } from "../Translation";
import { EditorState } from "draft-js";
import "draft-js/dist/Draft.css";
import RichTextEditor from "./RichTextEditor";
import moment from "moment";
import { toJS } from "mobx";

@inject("AppStore")
@observer
class FactoryFormStep extends React.Component {
  dates = datePicker();

  constructor(props) {
    super(props);
    this.state = { editorState: EditorState.createEmpty() };
    this.onChange = (editorState) => this.setState({ editorState });
  }

  render() {
    const { store } = this.props;

    return (
      <div className="step-payment-content" id="pdf-step">
        <div className="left">
          <h2>FACTORY FORM</h2>

          <div className="factory-section">
            <div className="form">
              <div className="form-item">
                <label>Revision</label>
                <select
                  value={store.factoryForm.revision}
                  onChange={(e) =>
                    (store.factoryForm.revision = e.target.value)
                  }
                >
                  {Array.from(Array(20).keys()).map((x) => (
                    <option key={x} value={x}>
                      {x}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-item">
                <label>Model Year</label>
                <input
                  required
                  type="text"
                  name="year"
                  value={store.factoryForm.year}
                  onChange={(e) => (store.factoryForm.year = e.target.value)}
                />
              </div>

              <div className="form-item">
                <label>Date of Order</label>
                <input
                  required
                  type="text"
                  name="date_of_order"
                  value={store.factoryForm.date_of_order}
                  onChange={(e) =>
                    (store.factoryForm.date_of_order = e.target.value)
                  }
                />
              </div>

              <div className="form-item">
                <label>Hull Number</label>
                <input
                  required
                  type="text"
                  name="hull_number"
                  value={store.factoryForm.hull_number}
                  onChange={(e) =>
                    (store.factoryForm.hull_number = e.target.value)
                  }
                />
              </div>

              <div className="form-item">
                <label>Customer Name</label>
                <input
                  required
                  type="text"
                  name="customer_name"
                  value={store.factoryForm.customer_name}
                  onChange={(e) =>
                    (store.factoryForm.customer_name = e.target.value)
                  }
                />
              </div>

              <div className="form-item">
                <label>Delivery Address</label>
                <input
                  required
                  type="text"
                  name="delivery_address"
                  value={store.factoryForm.delivery_address}
                  onChange={(e) =>
                    (store.factoryForm.delivery_address = e.target.value)
                  }
                />
              </div>

              <div className="form-item">
                <label>Preffered Delivery Date</label>
                <select
                  value={store.factoryForm.delivery}
                  onChange={(e) =>
                    (store.factoryForm.delivery = e.target.value)
                  }
                >
                  <option></option>
                  {this.dates.map((d, i) => (
                    <option
                      value={d.value}
                      style={d.style}
                      key={i}
                      disabled={d.disabled}
                    >
                      {d.text}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-item" style={{ display: "none" }}>
                <label>Electrical System</label>
                <select
                  onChange={(e) =>
                    (store.factoryForm.electrical_system = e.target.value)
                  }
                  value={store.factoryForm.electrical_system}
                >
                  <option value="v_110">110 VOLT ( US )</option>
                  <option value="v_220">220 VOLT ( EU )</option>
                </select>
              </div>

              <div className="form-item">
                <label>Contact Person</label>
                <input
                  required
                  type="text"
                  name="contact_person"
                  value={store.factoryForm.contact_person}
                  onChange={(e) =>
                    (store.factoryForm.contact_person = e.target.value)
                  }
                />
              </div>

              <div className="form-item">
                <label>Contact Mobile</label>
                <input
                  required
                  type="text"
                  name="contact_phone"
                  value={store.factoryForm.contact_phone}
                  onChange={(e) =>
                    (store.factoryForm.contact_phone = e.target.value)
                  }
                />
              </div>

              <div className="form-item">
                <label>Contact Email</label>
                <input
                  required
                  type="text"
                  name="contact_email"
                  value={store.factoryForm.contact_email}
                  onChange={(e) =>
                    (store.factoryForm.contact_email = e.target.value)
                  }
                />
              </div>

              <div className="form-item"></div>

              <div className="form-item" style={{ gridColumn: "1/3" }}>
                <label>Special Notes</label>
                <RichTextEditor
                  value={store.factoryForm.factory_notes}
                  onChange={(e) => (store.factoryForm.factory_notes = e)}
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 50,
              }}
            >
              <button
                style={{ margin: 10, padding: "10px 20px", flex: 1 }}
                type="submit"
                className="button__clean"
                onClick={(e) => store.onFactorySubmit(e)}
              >
                GENERATE FACTORY ORDER PDF
              </button>
            </div>
          </div>
        </div>
        <div className="options-wrapper">
          {store.summary.model.selected_picture && (
            <img
              className="image"
              src={`${store.summary.model.selected_picture}`}
              alt=""
            />
          )}
          <div
            className="model-name"
            dangerouslySetInnerHTML={{ __html: store.data.name }}
          ></div>

          <div className="upgrades-wrapper">
            <div className="option-group-title">
              <div
                className="selectedModel__subtitle--top uc"
                style={{ border: "none" }}
              >
                ORDER SUMMARY
              </div>
            </div>
            <div className="upgrades">
              {Object.keys(store.summary.upgradeOptionsDisplay).map(
                (step, i) => (
                  <div key={i} className="">
                    <div
                      style={{
                        paddingBottom: 10,
                        paddingTop: 10,
                        borderBottom: "1px solid #ddd",
                      }}
                    >
                      <div className="upgradeoption-step">{step}</div>
                      {Object.keys(
                        store.summary.upgradeOptionsDisplay[step]
                      ).map((option_group_title, j) => (
                        <div key={j}>
                          {option_group_title !== "null" && (
                            <div
                              className="upgradeoption-option-group-title"
                              style={{ marginLeft: 35 }}
                            >
                              {option_group_title}
                            </div>
                          )}

                          {Object.keys(
                            store.summary.upgradeOptionsDisplay[step][
                              option_group_title
                            ]
                          ).map((option_group, k) => (
                            <React.Fragment key={k}>
                              <div
                                className="upgradeoption-option-group"
                                style={{
                                  marginLeft:
                                    option_group_title !== "null" ? 45 : 35,
                                }}
                              >
                                {option_group}
                              </div>

                              {store.summary.upgradeOptionsDisplay[step][
                                option_group_title
                              ][option_group].map((option, l) => (
                                <div
                                  className="upgradeoption-option option-row"
                                  key={l}
                                  style={{
                                    marginLeft:
                                      option_group_title !== "null" ? 55 : 45,
                                  }}
                                >
                                  <span
                                    className="text"
                                    style={{
                                      marginLeft: 10,
                                    }}
                                  >
                                    <div className="upgradeoption-option">
                                      {option.title}
                                    </div>
                                  </span>
                                  <span
                                    className="price animate__animated animate__fadeIn"
                                    style={{
                                      color: store.data.color,
                                      fontWeight: "bold",
                                      flex: 1,
                                      textAlign: "right",
                                    }}
                                  >
                                    {convertCurrencySummaryLocation(
                                      option.price,
                                      store.location
                                    )}
                                  </span>
                                </div>
                              ))}
                            </React.Fragment>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
          <div className="purchase-wrapper">
            <div className="purchase-price">
              <div className="purchase-price-text">
                <Translation lang={store.page.lang} t="footer_purchase_price" />
              </div>
              <div className="purchase-price-amount">
                {convertCurrencySummaryLocation(
                  store.summary.purchase_price,
                  store.location
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FactoryFormStep;
