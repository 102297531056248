import React from "react";
import ArrawLeftSvg from "../icon/arrow_left_1.svg";
import ArrawRightSvg from "../icon/arrow_right_1.svg";
import ArrawUpSvg from "../icon/arrow_up_1.svg";

export const Step = (x) => {
  return (
    <div className="step-wrapper">
      {x.selected && (
        <span className="step-arrow-left">
          <img src={ArrawLeftSvg} />
        </span>
      )}
      <div
        className={`step ${x.visited && "step-visited"} ${
          x.selected && "step-active"
        }`}
        style={{ borderColor: x.color }}
        onClick={x.onSelected}
      >
        {x.title}
      </div>
      {x.selected && (
        <span className="step-arrow-right">
          <img src={ArrawRightSvg} />
        </span>
      )}
    </div>
  );
};
