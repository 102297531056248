import "./App.css";

import React from "react";
import { inject, observer } from "mobx-react";
import { Step } from "./Components/Step";
import ImageStep from "./Components/ImageStep";
import GridStep from "./Components/GridStep";

import CountUp from "react-countup";
import SummaryStep from "./Components/SummaryStep";
import PdfStep from "./Components/PdfStep";
import CompleteStep from "./Components/CompleteStep";
import PaymentStep from "./Components/PaymentStep";
import { Translation } from "./Translation";
import { appConfig } from "./config";
import { Loading } from "./Components/Loading";
import SmoothScrolling from "./Helpers/smootScrolling";
import { ImWhatsapp, ImPhone } from "react-icons/im";
import { AiOutlineMail } from "react-icons/ai";
import { SpecPopup } from "./Components/SpecPopup";
import DependencyPopup from "./Components/DependencyPopup";
import DependantPopup from "./Components/DependantPopup";
import BrokerStep from "./Components/BrokerStep";
import { toJS } from "mobx";
import FactoryFormStep from "./Components/FactoryFormStep";

@inject("AppStore")
@observer
class App extends React.Component {
  store = this.props.AppStore;

  componentDidMount() {
    const { slug, location, number } = this.props.match.params;

    this.store.getLocation(location);
    this.store.loadConfig();

    if (
      !number &&
      this.store.user &&
      this.store.user.user &&
      this.store.user.user.location &&
      this.store.user.user.location.name !== location
    ) {
      window.location =
        "/" + this.store.user.user.location.name + "/build/" + slug;
      return;
    }

    // get path
    this.store.init(slug, number);
  }

  render() {
    const { page, data } = this.store;

    if (page.loading) return <Loading />;

    return (
      <div className="app">
        <div className="header">
          <div className="logo-wrapper">
            <a
              href={`${appConfig.locale.url}`}
              style={{
                display: "flex",
                alignItems: "center",
                color: "#000",
                textDecoration: "none",
                fontSize: 12,
                fontWeight: "bold",
              }}
            >
              <img
                src={appConfig.locale.api + this.store.configuration.logo.url}
                className="logo"
                alt=""
              />
              <span>
                {this.store.location && this.store.location.displayname}
              </span>
            </a>
          </div>
          <div
            className="steps"
            style={page.hideSteps ? { display: "none" } : null}
          >
            {!this.store.page.completed && (
              <React.Fragment>
                {data.steps.map((step, i) => (
                  <Step
                    key={i}
                    order={i + 1}
                    visited={i <= page.stepIndex}
                    selected={i === page.stepIndex}
                    onSelected={() => {
                      this.store.onStepSelected(step, i);
                    }}
                    title={step[this.store.langify("title")]}
                    color={this.store.data.color}
                  />
                ))}
                <Step
                  order={page.summaryStep}
                  visited={page.summaryStep <= page.stepIndex}
                  selected={page.summaryStep <= page.stepIndex}
                  onSelected={() => {
                    this.store.onStepSelected({}, page.summaryStep);
                  }}
                  title={this.store.page.lang === "en" ? "SUMMARY" : "ÖZET"}
                />
              </React.Fragment>
            )}
          </div>

          {this.store.user ? (
            <div
              className="header-user-inner"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingRight: 10,
                fontSize: 13,
              }}
            >
              <span>{this.store.user.user.fullname} -&nbsp;</span>
              <a onClick={this.store.logout} style={{ color: "#000" }}>
                LOGOUT
              </a>
            </div>
          ) : (
            <React.Fragment>
              <div
                className="social"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  width: 150,
                }}
              >
                <div>
                  <a
                    href={`https://api.whatsapp.com/send?phone=${
                      this.store.location && this.store.location.phone_whatsapp
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ImWhatsapp color={this.store.data.color} fontSize="20" />
                  </a>
                </div>
                <div>
                  <a
                    href={`mailto:${
                      this.store.location && this.store.location.email
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <AiOutlineMail
                      color={this.store.data.color}
                      fontSize="24"
                    />
                  </a>
                </div>
                <div>
                  <a
                    href={`tel:${
                      this.store.location && this.store.location.phone
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ImPhone color={this.store.data.color} fontSize="20" />
                  </a>
                </div>
              </div>
              {/* <div
                className="header-user-inner"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingRight: 10,
                  fontSize: 13,
                }}
              >
                <a
                  href={`/account`}
                  onClick={this.store.logout}
                  style={{ color: "#000" }}
                >
                  Account
                </a>
              </div> */}
            </React.Fragment>
          )}
        </div>
        <div className="mobile-title">
          <span dangerouslySetInnerHTML={{ __html: data.name }}></span>
          <div className="pricing">
            <span style={{ fontSize: 12 }}>
              PURCHASE <strong>PRICE:</strong>
            </span>
            <br />
            <CountUp
              className="price-text orbitron"
              separator=","
              decimals={2}
              decimal="."
              prefix={this.store.location.symbol}
              duration={1}
              start={this.store.lastPrice}
              end={this.store.summary.purchase_price}
            />
          </div>
        </div>
        <div className="content">
          {page.completeStep !== page.stepIndex && (
            <React.Fragment>
              {!page.hideSteps &&
                data.steps.map((step, i) => (
                  <div
                    key={i}
                    className={`step-content-inner visible-mobile ${
                      i === page.stepIndex &&
                      "flex animate__animated animate__fadeIn"
                    }`}
                    data-title={step.title}
                  >
                    <div className="title">
                      <span>{step[this.store.langify("title")]}</span>
                    </div>
                    {step.type === "Image" && (
                      <ImageStep store={this.store} step={step} data={data} />
                    )}
                    {step.type === "Grid" && (
                      <GridStep store={this.store} step={step} />
                    )}
                  </div>
                ))}
              <div
                id="step-summary"
                className={`step-content-inner visible-mobile ${
                  page.summaryStep === page.stepIndex &&
                  "flex animate__animated animate__fadeIn"
                }`}
                data-title="SUMMARY"
              >
                <div className="title" id="summary">
                  {this.store.page.lang === "en" ? "SUMMARY" : "ÖZET"}
                </div>
                <SummaryStep store={this.store} />
              </div>

              <div
                className={`step-content-inner ${
                  page.pdfStep === page.stepIndex &&
                  "flex animate__animated animate__fadeIn"
                }`}
                data-title="Pdf"
              >
                <div className="title" id="pdf">
                  PDF
                </div>
                <PdfStep store={this.store} />
              </div>

              <div
                className={`step-content-inner ${
                  page.paymentStep === page.stepIndex &&
                  "flex animate__animated animate__fadeIn"
                }`}
                data-title="Payment"
              >
                <div className="title" id="payment">
                  Payment
                </div>
                <PaymentStep store={this.store} />
              </div>

              <div
                className={`step-content-inner ${
                  page.brokerStep === page.stepIndex &&
                  "flex animate__animated animate__fadeIn"
                }`}
                data-title="Broker"
              >
                <div className="title" id="broker">
                  BROKER
                </div>
                <BrokerStep store={this.store} />
              </div>

              <div
                className={`step-content-inner ${
                  page.factoryStep === page.stepIndex &&
                  "flex animate__animated animate__fadeIn"
                }`}
                data-title="Broker"
              >
                <div className="title" id="broker">
                  FACTORY FORM
                </div>
                <FactoryFormStep store={this.store} />
              </div>
            </React.Fragment>
          )}

          {page.completeStep === page.stepIndex && (
            <div
              className={`step-content-inner ${"flex animate__animated animate__fadeIn"}`}
              data-title="Complete"
            >
              <CompleteStep store={this.store} />
            </div>
          )}
        </div>
        <div
          className="footer"
          style={{
            display: page.completeStep === page.stepIndex ? "none" : "flex",
          }}
        >
          {!this.store.page.completed && (
            <React.Fragment>
              <div className="price-summary">
                <div className="payment-options">
                  <span>
                    PURCHASE <strong>PRICE:</strong>
                  </span>
                </div>
                <div className="price-display">
                  <CountUp
                    className="price-text orbitron"
                    separator=","
                    decimals={2}
                    decimal="."
                    prefix={this.store.location.symbol}
                    duration={1}
                    start={this.store.lastPrice}
                    end={this.store.summary.purchase_price}
                  />
                </div>
                <div className="buttons_mobile">
                  <div className="modelSelector__cleanbutton">
                    <a
                      onClick={() => {
                        this.store.onStepSelected({}, this.store.page.pdfStep);

                        setTimeout(() => {
                          SmoothScrolling.scrollTo("step-summary");
                        }, 400);
                      }}
                    >
                      SEND PDF
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="estimated"
                style={{
                  flex: 2,
                  display: "flex",
                  alignItems: "center",
                  fontSize: 12,
                  justifyContent: "flex-end",
                }}
              >
                <SpecPopup model={this.store.data} />
              </div>
              <div className="delivery">
                <div className="button-wrapper">
                  {page.stepIndex === 0 && (
                    <div
                      className="modelSelector__cleanbutton"
                      style={{ marginRight: 15 }}
                    >
                      <a href={`/`}>
                        <Translation lang={this.store.page.lang} t="prev" />
                      </a>
                    </div>
                  )}

                  {page.stepIndex > 0 &&
                    (page.stepIndex === page.paymentStep ||
                    page.stepIndex === page.brokerStep ||
                    page.stepIndex === page.factoryStep ? (
                      <div
                        className="modelSelector__cleanbutton"
                        style={{ marginRight: 15 }}
                      >
                        <a
                          onClick={() =>
                            this.store.onStepSelected({}, page.summaryStep)
                          }
                        >
                          <Translation lang={this.store.page.lang} t="prev" />
                        </a>
                      </div>
                    ) : (
                      <div
                        className="modelSelector__cleanbutton"
                        style={{ marginRight: 15 }}
                      >
                        <a onClick={this.store.prevStep}>
                          <Translation lang={this.store.page.lang} t="prev" />
                        </a>
                      </div>
                    ))}

                  {page.stepIndex === page.summaryStep &&
                    this.store.user &&
                    this.store.user.user.factory_order && (
                      <div
                        className="modelSelector__cleanbutton"
                        style={{ marginRight: 15 }}
                      >
                        <a
                          onClick={() =>
                            this.store.onStepSelected({}, page.factoryStep)
                          }
                        >
                          FACTORY ORDER
                        </a>
                      </div>
                    )}

                  {page.stepIndex === page.summaryStep &&
                    (this.store.broker ? (
                      <React.Fragment>
                        <div className="modelSelector__cleanbutton">
                          <a
                            onClick={() =>
                              this.store.onBrokerageStepSelected(
                                {},
                                this.store.page.brokerStep
                              )
                            }
                          >
                            SALES AGREEMENT
                          </a>
                        </div>
                        <div
                          className="modelSelector__cleanbutton"
                          style={{ marginLeft: 15 }}
                        >
                          <a
                            onClick={() =>
                              this.store.onStepSelected(
                                {},
                                this.store.page.pdfStep
                              )
                            }
                          >
                            SEND PDF
                          </a>
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div className="modelSelector__cleanbutton">
                          <a
                            onClick={() =>
                              this.store.onStepSelected(
                                {},
                                this.store.page.pdfStep
                              )
                            }
                          >
                            SEND PDF
                          </a>
                        </div>
                        {appConfig.locale.has_payment && (
                          <div
                            className="modelSelector__cleanbutton"
                            style={{ marginLeft: 15 }}
                          >
                            <a
                              onClick={() =>
                                this.store.onStepSelected(
                                  {},
                                  this.store.page.paymentStep
                                )
                              }
                            >
                              RESERVE NOW
                            </a>
                          </div>
                        )}
                      </React.Fragment>
                    ))}

                  {/* {page.stepIndex == page.brokerStep && (
                    <React.Fragment>
                      <div
                        className="modelSelector__cleanbutton"
                        style={{ marginRight: 15 }}
                      >
                        <a
                          onClick={(e) => {
                            this.store.onBrokerPdfSubmit(e);
                          }}
                        >
                          SEND PDF
                        </a>
                      </div>
                      <div className="modelSelector__cleanbutton">
                        <a
                          onClick={(e) => {
                            this.store.onBrokerPdfDownload(e);
                          }}
                        >
                          DOWNLOAD PDF
                        </a>
                      </div>
                    </React.Fragment>
                  )} */}

                  {page.stepIndex === page.pdfStep && (
                    <React.Fragment>
                      <div
                        className="modelSelector__cleanbutton"
                        style={{ marginRight: 15 }}
                      >
                        <a
                          onClick={(e) => {
                            var form = document.getElementById("pdf_form");

                            if (form.reportValidity()) {
                              this.store.onPdfSubmit(e);
                            }
                          }}
                        >
                          SEND PDF
                        </a>
                      </div>
                      {this.store.user && (
                        <div className="modelSelector__cleanbutton">
                          <a
                            onClick={(e) => {
                              this.store.onPdfDownload(e);
                            }}
                          >
                            DOWNLOAD PDF
                          </a>
                        </div>
                      )}
                    </React.Fragment>
                  )}

                  {page.stepIndex < page.summaryStep &&
                    (this.store.checkNext ? (
                      this.store.page.nextEnabled ? (
                        <div className="modelSelector__cleanbutton">
                          <a onClick={this.store.nextStep}>
                            <Translation lang={this.store.page.lang} t="next" />
                          </a>
                        </div>
                      ) : (
                        <div className="modelSelector__cleanbutton disabled">
                          <a>
                            <Translation lang={this.store.page.lang} t="next" />
                          </a>
                        </div>
                      )
                    ) : (
                      <div className="modelSelector__cleanbutton disabled">
                        <a title="You must select at least one option">
                          <Translation lang={this.store.page.lang} t="next" />
                        </a>
                      </div>
                    ))}
                </div>
              </div>
            </React.Fragment>
          )}
        </div>

        <DependencyPopup />
        <DependantPopup />
      </div>
    );
  }
}

export default App;
